import { useEffect, useState } from 'react'
import { useUserContext } from 'context/UserContext'
import { useTranslation } from 'react-i18next'
import { Row, Col, Button, Dropdown, Space } from 'antd'
import type { MenuProps } from 'antd'
import WoundCareService from 'services/wound-care-service'
import SaivaWoundsTable from 'components/Tables/BasicTable/SaivaWoundsTable'
import OptionSelect from 'components/OptionSelect/OptionSelectWounds/OptionSelectWounds'
import FacilitiesSelectCard from 'components/OptionSelect/OptionSelectWounds/FacilitiesSelectWounds'
import RegionService from 'services/region-service'
import { SaivaSort, SortDirection } from 'services/utils/api-utils'
import { ReactComponent as FilterIcon } from 'assets/icons/filters.svg'
import { ReactComponent as DownloadIcon } from 'assets/icons/download.svg'
import { ReactComponent as StarIllustration } from 'assets/icons/illustration-star.svg'
import { ReactComponent as CheckIllustration } from 'assets/icons/illustration-check.svg'
import { ReactComponent as WarningIllustration } from 'assets/icons/warning-illustartion.svg'
import styles from './Wounds.module.scss'
import LoadingIcon from 'components/Skeleton/LoadinIcon/LoadingIcon'
import { showErrorToast, showSuccessToast } from 'utils'
import moment from 'moment'
import SkeletonCard from 'components/Skeleton/Paragraph/SkeletonParagraph'
import DatePickerCard, { Range } from 'components/DatePicker/SaivaNewDatePicker'
import { mixpanelInstance } from 'utils/mixpanel'
import { compareArrays } from 'utils/helper'

interface Day {
  daily: { start: Date; end: Date }
}

interface Filters {
  date_start: string
  date_end: string
  selectedRegions: any
  page: number
  size: number
  payers?: string[]
  physicians?: string[]
  hidden_columns?: string[]
  special_filter?: string
  order_types?: string[]
  order_statuses?: string[]
  widget?: string | null
  actions?: string[] | null
  search?: string
  sort?: SaivaSort
  defaultDates?: Day
  facilityIds?: string[]
}

const parseDate = (date) => {
  let day = date.getDate().toString()
  let month = (date.getMonth() + 1).toString()
  let year = date.getFullYear()
  if (Number(day) < 10) {
    day = '0' + day
  }
  if (Number(month) < 10) {
    month = `0${month}`
  }
  return `${year}-${month}-${day}`
}

const rowOptions = [
  { label: '5', value: 5 },
  { label: '10', value: 10 },
  { label: '20', value: 20 },
  { label: '50', value: 50 },
  { label: '100', value: 100 }
]

const statusValues = [
  { id: 'New', label: 'New' },
  { id: 'Inactive', label: 'Inactive' },
  { id: 'Reviewed_On_Caseload', label: 'Reviewed - On Caseload' },
  { id: 'Reviewed_Payer_Ineligible', label: 'Reviewed - Payer Ineligible' },
  { id: 'Reviewed_Order_Ineligible', label: 'Reviewed - Order Ineligible' },
  { id: 'Reviewed_Missing_Doc.', label: 'Reviewed - Missing Doc.' }
]

const orderTypes = [
  { id: 'Wound', label: 'Wound' },
  { id: 'Catheter', label: 'Catheter' },
  { id: 'Ostomy', label: 'Ostomy' },
  { id: 'Tracheostomy', label: 'Tracheostomy' }
]

const saveFiltersToLocalStorage = (orgId: string, filter: any) => {
  try {
    const res = window.localStorage.getItem('woundsFilters')
    let value = res ? JSON.parse(res) : undefined
    let updatedFilter: any = filter
    if (value && filter)
      window.localStorage.setItem(
        'woundsFilters',
        JSON.stringify({
          ...value,
          [orgId]: { ...value[orgId], ...updatedFilter }
        })
      )
    else
      window.localStorage.setItem(
        'woundsFilters',
        JSON.stringify({
          ...value,
          [orgId]: updatedFilter
        })
      )
  } catch (e) {}
}

const loadFiltersFromLocalStorage = (orgId: string): any | undefined => {
  try {
    const res = window.localStorage.getItem('woundsFilters')
    if (res && JSON.parse(res)[orgId]) {
      return JSON.parse(res)[orgId]
    } else return undefined
  } catch (e) {
    return undefined
  }
}

const defaultDate = {
  daily: {
    start: new Date(moment().subtract(30, 'days').format('YYYY-MM-DD')),
    end: new Date()
  }
}

let abortData = new AbortController()

const defaultFilters: Filters = {
  date_start: moment().subtract(30, 'days').format('YYYY-MM-DD'),
  date_end: moment().format('YYYY-MM-DD'),
  selectedRegions: [],
  page: 1,
  size: 10,
  search: '',
  order_statuses: statusValues.map((i) => i.id),
  order_types: orderTypes.map((i) => i.id)
}

const changedFilters = (filters: Filters, selectedFilters: Filters) => {
  const keys = Object.keys(filters)
  const results = keys
    .map((key) => {
      if (selectedFilters[key] && filters[key]) {
        const selectedValues = Object.values(selectedFilters[key])
        const filterValues = Object.values(filters[key])

        return selectedValues.map((value) => {
          const valueIncluded = filterValues.includes(value)
          if (!valueIncluded) {
            return key
          }
        })
      }
    })
    .flat(1)
    .filter((item) => item)
  return [...new Set(results)]
}

const filtersSelectedAll = (filters: Filters, selectedFilters: Filters) => {
  const keys = Object.keys(filters)
  const results = keys
    .map((key) => {
      if (selectedFilters[key] && filters[key]) {
        const selectedValues = Object.values(selectedFilters[key])
        const filterValues = Object.values(filters[key])
        if (selectedValues.length === 0 && filterValues.length > 0) {
          return key
        } else if (compareArrays(filterValues, selectedValues)) {
          return key
        }
      } else if (
        selectedFilters[key] == undefined ||
        filters[key] == undefined
      ) {
        return key
      }
    })
    .flat(1)
    .filter((item) => item)
  return [...new Set(results)]
}

const compareValues = (value: string[], selectedValue: string[]) => {
  return selectedValue.map((j: string) => value.includes(j)).includes(false)
}

const Wounds = () => {
  const { t } = useTranslation()
  const today = new Date()
  const userContext = useUserContext()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<any>([])
  const [filters, setFilters] = useState<any>(undefined)
  const [stats, setStats] = useState<any>({})
  const [hideFilters, setHideFilters] = useState<boolean>(true)
  const [page, setPage] = useState<number>(1)
  const [total, setTotal] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [regions, setRegions] = useState<any>([])
  const [fileLoading, setFileLoading] = useState<boolean>(false)
  const [selectedFilters, setSelectedFilters] = useState<any>(undefined)
  const [allTimeData, setAllTimeData] = useState<boolean>(false)
  const [defaultDates, setDefaultDates] = useState<any>(defaultDate)
  const [widgetFilter, setWidgetFilter] = useState<any>(undefined)
  const [tableSort, setTableSort] = useState<SaivaSort | undefined>({
    property: 'order_status',
    direction: SortDirection.Ascending
  })
  const localstorageFilters = loadFiltersFromLocalStorage(
    userContext.currentOrg.id
  )

  useEffect(() => {
    mixpanelInstance.orderCaptureNavigationChange()
    abortData = new AbortController()
    return () => {
      abortData.abort()
    }
  }, [])

  useEffect(() => {
    setRegions([])
    init()
  }, [userContext.currentOrg.id])

  const init = async () => {
    await fetchRegions().then((regions) => {
      if (regions && regions.length > 0) {
        if (localstorageFilters) {
          setWidgetFilter(localstorageFilters.widget)
          localstorageFilters.defaultDates?.daily
            ? setDefaultDates({
                daily: {
                  start: new Date(localstorageFilters.defaultDates.daily.start),
                  end: new Date(localstorageFilters.defaultDates.daily.end)
                }
              })
            : setDefaultDates(defaultDate)
          setFilterAndRefetch(
            {
              ...localstorageFilters,
              search: ''
            },
            false
          )
        } else {
          setFilterAndRefetch(
            { ...defaultFilters, selectedRegions: regions },
            false
          )
        }
        setRegions(regions)
      }
    })
  }

  useEffect(() => {
    if (regions.length > 0) {
      setFilterAndRefetch(selectedFilters, true)
    }
  }, [page, rowsPerPage, tableSort, regions.length])

  const setFilterAndRefetch = (filter: Filters, refetch: boolean) => {
    setSelectedFilters((prev) => {
      const newFilters = { ...prev, ...filter }

      if (regions.length > 0) {
        const selectedFacilities =
          newFilters.selectedRegions?.map((i) => i.facilities).flat(1) ?? []
        const facilities = regions.map((i) => i.facilities).flat(1) ?? []
        saveFiltersToLocalStorage(userContext.currentOrg.id, newFilters)
        if (refetch) {
          const valuesNotIncluded = compareValues(
            facilities.map((i) => {
              return i.id
            }),
            selectedFacilities.map((i) => {
              return i.id
            })
          )
          if (valuesNotIncluded) {
            setFilterAndRefetch(
              { ...newFilters, selectedRegions: regions },
              true
            )
          } else {
            fetchWounds({
              ...filter,
              facilityIds: selectedFacilities.map((i) => {
                return i.id
              }),
              page: page,
              size: rowsPerPage,
              sort: tableSort
            })
          }
        }
      }

      return newFilters
    })
  }

  const handleSortClick = (propertyName: string) => {
    const currentDirection = tableSort?.direction ?? SortDirection.Descending
    setTableSort({
      property: propertyName,
      direction:
        currentDirection === SortDirection.Ascending
          ? SortDirection.Descending
          : SortDirection.Ascending
    })
    mixpanelInstance.orderCaptureSortTable({
      property: propertyName,
      direction:
        currentDirection === SortDirection.Ascending
          ? SortDirection.Descending
          : SortDirection.Ascending
    })
  }

  const handleResetWidgets = () => {
    setFilterAndRefetch(
      {
        ...selectedFilters,
        order_statuses: statusValues.map((i) => i.id),
        actions: null,
        widget: null,
        date_start: moment().subtract(30, 'days').format('YYYY-MM-DD'),
        date_end: moment().format('YYYY-MM-DD'),
        defaultDates: defaultDate
      },
      true
    )
    setWidgetFilter(undefined)
    setDefaultDates(defaultDate)
    setAllTimeData(false)
  }

  const handleSetWidgets = (widgetValue, name) => {
    setFilterAndRefetch(
      {
        ...selectedFilters,
        order_statuses: widgetValue,
        widget: name,
        date_start: moment()
          .subtract(365 * 5, 'days')
          .format('YYYY-MM-DD'),
        date_end: moment().format('YYYY-MM-DD'),
        defaultDates: undefined
      },
      true
    )
    setWidgetFilter(name)
    setDefaultDates(!defaultDates)
    setAllTimeData(true)
    mixpanelInstance.orderCaptureWidgetStatusSelect(widgetValue)
  }

  const handleSetDate = (date) => {
    setAllTimeData(false)
    setFilterAndRefetch(
      {
        ...selectedFilters,
        date_start: moment(date.start).format('YYYY-MM-DD'),
        date_end: moment(date.end).format('YYYY-MM-DD'),
        defaultDates: {
          daily: {
            start: new Date(moment(date.start).format('YYYY-MM-DD')),
            end: new Date(moment(date.end).format('YYYY-MM-DD'))
          }
        }
      },
      true
    )
    mixpanelInstance.orderCaptureSelectDates({
      date_start: moment(date.start).format('YYYY-MM-DD'),
      date_end: moment(date.end).format('YYYY-MM-DD')
    })
  }

  const fetchWounds = async (
    selectedFilters: Filters,
    dynamicFilters?: Filters
  ) => {
    abortData.abort()
    abortData = new AbortController()
    setLoading(true)
    let filtersForRequest = selectedFilters
    let filtersForCompare = dynamicFilters ? dynamicFilters : filters
    if (filtersForCompare && selectedFilters) {
      const newSelectedAllFilters = filtersSelectedAll(
        filtersForCompare,
        selectedFilters
      )
      if (newSelectedAllFilters && newSelectedAllFilters.length > 0) {
        let newFilters = {}
        for (const filterKey of newSelectedAllFilters) {
          if (filterKey) {
            newFilters = {
              ...newFilters,
              [filterKey]: []
            }
          }
        }
        filtersForRequest = { ...selectedFilters, ...newFilters }
      }
    }
    await WoundCareService.getWoundCareItems(
      userContext.currentMedicalSupplyOrg.id,
      userContext.currentOrg.id,
      filtersForRequest,
      abortData
    )
      .then(async (res) => {
        if (!res) return
        const lastPage = Math.ceil(res?.total ? res.total / res.size : 1)
        if (page > lastPage) {
          setPage(lastPage)
        }
        const newChangedFilters = changedFilters(res.filters, filtersForRequest)
        if (newChangedFilters && newChangedFilters.length > 0) {
          let newFilters = {}
          for (const filterKey of newChangedFilters) {
            if (filterKey) {
              newFilters = {
                ...newFilters,
                [filterKey]: res.filters[filterKey]
              }
            }
          }
          fetchWounds({ ...selectedFilters, ...newFilters }, res.filters)
        }
        setData(res.items)
        setStats(res.stats)
        setTotal(res.total)
        setFilters((prev: Filters) => {
          const prevFilters = prev ? prev : res.filters
          const newSelectedAllFilters = filtersSelectedAll(
            prevFilters,
            selectedFilters
          )
          if (newSelectedAllFilters && newSelectedAllFilters.length > 0) {
            let newFilters = {}
            for (const filterKey of newSelectedAllFilters) {
              if (filterKey) {
                newFilters = {
                  ...newFilters,
                  [filterKey]: res.filters[filterKey]
                }
              }
            }
            setFilterAndRefetch({ ...selectedFilters, ...newFilters }, false)
          }
          return res.filters
        })
        setLoading(false)
      })
      .catch((err) => {
        if (err.name === 'CanceledError') {
          return
        } else {
          setLoading(false)
          setData([])
          if (err.name === 'ApiError') {
            showErrorToast(t(err.errorCode))
          } else {
            showErrorToast(t(err.errorCode))
          }
          throw err
        }
      })
  }

  const exportPdf = async () => {
    setFileLoading(true)
    await WoundCareService.exportPDFWoundCareItems(
      userContext.currentMedicalSupplyOrg.id,
      userContext.currentOrg.id,
      {
        ...selectedFilters,
        sort: tableSort
      }
    )
      .then((res) => {
        var file = new Blob([res], { type: 'application/pdf;base64' })
        var fileURL = URL.createObjectURL(file)
        window.open(fileURL)
        setFileLoading(false)
        mixpanelInstance.orderCaptureFileDownload(
          fileURL,
          'pdf',
          {
            date_start: selectedFilters?.date_start,
            date_end: selectedFilters?.date_end
          },
          selectedFilters.selectedRegions
            ?.map((i) => i.facilities.map((f) => f.name) ?? [])
            .flat(1) ?? [],
          selectedFilters.selectedRegions?.map((i) => i.name) ?? []
        )
      })
      .catch((err) => {
        setFileLoading(false)
        if (err.name === 'ApiError') {
          showErrorToast(t(err.errorCode))
        } else {
          showErrorToast(t(err.errorCode))
        }
        throw err
      })
  }

  const exportCSV = async () => {
    setFileLoading(true)
    await WoundCareService.exportCSVWoundCareItems(
      userContext.currentMedicalSupplyOrg.id,
      userContext.currentOrg.id,
      {
        ...selectedFilters,
        sort: tableSort
      }
    )
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute(
          'download',
          `${parseDate(today)}-wound-care-export.csv`
        )
        document.body.appendChild(link)
        link.click()
        link.remove()
        setFileLoading(false)
        mixpanelInstance.orderCaptureFileDownload(
          url,
          'csv',
          {
            date_start: selectedFilters?.date_start,
            date_end: selectedFilters?.date_end
          },
          selectedFilters.selectedRegions
            ?.map((i) => i.facilities.map((f) => f.name) ?? [])
            .flat(1) ?? [],
          selectedFilters.selectedRegions?.map((i) => i.name) ?? []
        )
      })
      .catch((err) => {
        setFileLoading(false)
        if (err.name === 'ApiError') {
          showErrorToast(t(err.errorCode))
        } else {
          showErrorToast(t(err.errorCode))
        }
        throw err
      })
  }

  const actionWounds = async (action, orgIds, bulk) => {
    setLoading(true)
    await WoundCareService.setAction(
      userContext.currentMedicalSupplyOrg.id,
      userContext.currentOrg.id,
      { ...selectedFilters, ...orgIds },
      action
    )
      .then((res): any => {
        setLoading(false)
        if (res.items) {
          setData(
            data.map((obj) => res.items.find((o) => o.id === obj.id) || obj)
          )
          setStats(res.stats)
        }
        showSuccessToast('Operation Was Successful')
        mixpanelInstance.orderCaptureAction(action, orgIds, bulk)
      })
      .catch((err) => {
        setLoading(false)
        if (err.name === 'ApiError') {
          showErrorToast(t(err.errorCode))
        } else {
          showErrorToast(t(err.errorCode))
        }
        throw err
      })
  }

  const fetchRegions = async () => {
    const regions = await RegionService.getRegions(userContext.currentOrg.id)
      .then((res) => {
        return res
      })
      .catch((err) => {
        if (err.name === 'ApiError') {
          showErrorToast(t(err.errorCode))
        } else {
          showErrorToast(t(err.errorCode))
        }
        throw err
      })
    return regions
  }

  function showTotalTable(total, range) {
    return `${range[0]} - ${range[1]} from ${total}`
  }

  const pagination = {
    total: total,
    page: page,
    pageSize: rowsPerPage,
    showTotal: showTotalTable,
    onChange(newCurrent: number) {
      setPage(newCurrent)
    },
    onShowSizeChange(pageSize: number) {
      const lastPage = Math.ceil(total / pageSize)
      if (page > lastPage) {
        setPage(lastPage)
      }
      setRowsPerPage(pageSize)
    }
  }

  const menu = [
    {
      key: '1',
      label: (
        <button onClick={() => exportPdf()} className={styles.dropdownButton}>
          Export PDF
        </button>
      )
    },
    {
      key: '2',
      label: (
        <button onClick={() => exportCSV()} className={styles.dropdownButton}>
          Export CSV
        </button>
      )
    }
  ]
  const items: MenuProps['items'] = menu

  return (
    <div style={{ margin: '24px 0px' }}>
      <div className={styles.topBar}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <DatePickerCard
            rangeOptions={[Range.Daily]}
            defaultValue={defaultDates}
            onDateChange={(date) => {
              if (date.start && date.end) {
                handleSetDate(date)
              }
            }}
          />
        </div>
        <div>
          <Button
            className={`${styles.actionButton} ${styles.filtersButton}`}
            size="large"
            onClick={() => setHideFilters(!hideFilters)}
          >
            <FilterIcon style={{ marginRight: '8px', stroke: '#4070DC' }} />
            {hideFilters ? 'Show Filters' : 'Hide Filters'}
          </Button>

          <>
            <Dropdown menu={{ items }} overlayClassName={styles.dropdown}>
              <Button className={styles.actionButton} size="large">
                {fileLoading ? <LoadingIcon /> : <DownloadIcon />}
                <Space style={{ paddingLeft: '10px' }}>Export</Space>
              </Button>
            </Dropdown>
          </>
        </div>
      </div>
      {filters && (
        <Row className={`topCards ${hideFilters && styles.hidden}`}>
          <Col
            xs={24}
            md={24 / 2}
            xl={24 / 3}
            xxl={24 / 4}
            style={{ padding: '12px' }}
          >
            <FacilitiesSelectCard
              items={regions}
              selectedItems={selectedFilters.selectedRegions}
              onChange={(value) => {
                setAllTimeData(false)
                setFilterAndRefetch(
                  {
                    ...selectedFilters,
                    selectedRegions: value
                  },
                  true
                )
              }}
            />
          </Col>
          <Col
            xs={24}
            md={24 / 2}
            xl={24 / 3}
            xxl={24 / 4}
            style={{ padding: '12px' }}
          >
            <OptionSelect
              loading={loading}
              label="Facility States"
              items={filters.facility_states?.map((f) => ({
                id: f,
                label: f
              }))}
              selectedItems={selectedFilters?.facility_states?.map((f) => ({
                id: f,
                label: f
              }))}
              onChange={(value) => {
                setAllTimeData(false)
                setFilterAndRefetch(
                  {
                    ...selectedFilters,
                    facility_states: value.map((v) => v.id)
                  },
                  true
                )
              }}
              color="#4070DC"
            />
          </Col>
          <Col
            xs={24}
            md={24 / 2}
            xl={24 / 3}
            xxl={24 / 4}
            style={{ padding: '12px' }}
          >
            <OptionSelect
              loading={loading}
              label="Primary Payers"
              items={filters.payers?.map((f) => ({
                id: f,
                label: f
              }))}
              selectedItems={selectedFilters?.payers?.map((f) => ({
                id: f,
                label: f
              }))}
              onChange={(value) => {
                setAllTimeData(false)
                setFilterAndRefetch(
                  {
                    ...selectedFilters,
                    payers: value.map((v) => v.id)
                  },
                  true
                )
              }}
              color="#5EB8C6"
            />
          </Col>
          <Col
            xs={24}
            md={24 / 2}
            xl={24 / 3}
            xxl={24 / 4}
            style={{ padding: '12px' }}
          >
            <OptionSelect
              loading={loading}
              label="Status"
              items={statusValues}
              selectedItems={selectedFilters?.order_statuses?.map((f) => ({
                id: f,
                label: statusValues.find((s) => s.id === f)?.label
              }))}
              onChange={(value) => {
                setAllTimeData(false)
                setFilterAndRefetch(
                  {
                    ...selectedFilters,
                    order_statuses: value.map((v) => v.id)
                  },
                  true
                )
              }}
              color="#5EC7FF"
            />
          </Col>
          <Col
            xs={24}
            md={24 / 2}
            xl={24 / 3}
            xxl={24 / 4}
            style={{ padding: '12px' }}
          >
            <OptionSelect
              loading={loading}
              label="Order Types"
              items={orderTypes}
              selectedItems={selectedFilters?.order_types?.map((f) => ({
                id: f,
                label: f
              }))}
              onChange={(value) => {
                setAllTimeData(false)
                setFilterAndRefetch(
                  {
                    ...selectedFilters,
                    order_types: value.map((v) => v.id)
                  },
                  true
                )
              }}
              color="#C9C13E"
            />
          </Col>
        </Row>
      )}
      <Row gutter={[16, 12]} style={{ padding: '12px' }}>
        <Col xs={24} md={24 / 3} xl={24 / 3}>
          {!loading ? (
            <button
              className={styles.statCard}
              onClick={() => handleSetWidgets(['New'], 'New')}
            >
              <div style={{ paddingRight: '19px' }}>
                <h1>{t('wounds.widgets.newOrders.title')}</h1>
                <p>
                  {t('wounds.widgets.newOrders.text')}{' '}
                  {!allTimeData &&
                    t('wounds.widgets.newOrders.filteredDataMessage')}
                </p>
              </div>
              <div className={styles.number} style={{ color: '#CDC347' }}>
                {stats?.new_orders}
              </div>
              <StarIllustration className={styles.illustration} />
            </button>
          ) : (
            <button className={styles.statCard}>
              {SkeletonCard({
                loading: loading,
                repeat: 1,
                rows: 1,
                width: 300,
                title: true
              })}
            </button>
          )}
        </Col>
        <Col xs={24} md={24 / 3} xl={24 / 3}>
          {!loading ? (
            <button
              className={styles.statCard}
              onClick={() =>
                handleSetWidgets(
                  [
                    'Reviewed_On_Caseload',
                    'Reviewed_Payer_Ineligible',
                    'Reviewed_Order_Ineligible',
                    'Reviewed_Missing_Doc.'
                  ],
                  'Reviewed'
                )
              }
            >
              <div style={{ paddingRight: '19px' }}>
                <h1>{t('wounds.widgets.reviewedOrders.title')}</h1>
                <p>
                  {t('wounds.widgets.reviewedOrders.text')}{' '}
                  {!allTimeData &&
                    t('wounds.widgets.newOrders.filteredDataMessage')}
                </p>
              </div>
              <div className={styles.number} style={{ color: '#00BB13' }}>
                {stats?.reviewed_orders}
              </div>
              <CheckIllustration className={styles.illustration} />
            </button>
          ) : (
            <button className={styles.statCard}>
              {SkeletonCard({
                loading: loading,
                repeat: 1,
                rows: 1,
                width: 300,
                title: true
              })}
            </button>
          )}
        </Col>
        <Col xs={24} md={24 / 3} xl={24 / 3}>
          {!loading ? (
            <button
              className={styles.statCard}
              onClick={() =>
                handleSetWidgets(['Reviewed_Missing_Doc.'], 'Missing Doc.')
              }
            >
              <div style={{ paddingRight: '19px' }}>
                <h1>{t('wounds.widgets.missingDocsOrders.title')}</h1>
                <p>
                  {t('wounds.widgets.missingDocsOrders.text')}{' '}
                  {!allTimeData &&
                    t('wounds.widgets.newOrders.filteredDataMessage')}
                </p>
              </div>
              <div className={styles.number} style={{ color: '#F97801' }}>
                {stats?.missing_doc_orders}
              </div>
              <WarningIllustration className={styles.illustration} />
            </button>
          ) : (
            <button className={styles.statCard}>
              {SkeletonCard({
                loading: loading,
                repeat: 1,
                rows: 1,
                width: 300,
                title: true
              })}
            </button>
          )}
        </Col>
      </Row>
      <div style={{ padding: '12px' }}>
        <SaivaWoundsTable
          data={data}
          loading={loading}
          rowsPerPageOptions={rowOptions}
          downloadReport={undefined}
          fileLoading={false}
          rowKey="id"
          sortBy={undefined}
          sortChange={(value) => {}}
          onAction={actionWounds}
          onSearch={(e) => {
            setAllTimeData(false)
            setFilterAndRefetch(
              {
                ...selectedFilters,
                search: e.target.value
              },
              true
            )
          }}
          searchValue={selectedFilters?.search ? selectedFilters.search : ''}
          total={total}
          pagination={pagination}
          handleSortClick={handleSortClick}
          widgetFilter={widgetFilter}
          resetFilters={handleResetWidgets}
        />
      </div>
    </div>
  )
}

export default Wounds
